root {
    --red: #e0342e;
    --white: #FCFCFC;
    --gray: #B3C7BA;
    --black: #050505;
}

* {
    font-family: 'PT Sans', sans-serif;;
}


.banner {
    height: calc(100vh - 80px);
    background-color:var(--gray);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.banner .btn-primary {
    background-color: var(--red);
    color: var(--white);
    border-radius: 0;
    padding: .5rem 1rem;
    font-size: 1.2rem;
    border: 1px solid var(--red);
    cursor: pointer;
    width: 230px;
}

.about{
    padding: 1rem 4rem;
}

.about .about_title {
    text-align: center;
}


.causes{
    background-color: var(--gray);
    padding: 1rem 4rem;
}

.causes .causes_title{
    text-align: center;
}

.indicators {
    padding: 1rem 4rem;
}

.indicators .indicators_title{
    text-align: center;
}

.contact {
    background-color: var(--gray);
    padding: 1rem 4rem;
}

.contact .contact_title{
    text-align: center;
}





@media only screen and (min-width:320px) { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ }

@media only screen and (min-width:480px) { /* smartphones, Android phones, landscape iPhone */ }

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}   